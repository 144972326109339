import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import lodash from 'lodash';
import moment from 'moment';
import { ApiHelperService, CloudFnNames } from '../apiHelperService/api-helper.service';
import { UserService } from '../user/user.service';
import { environment } from '../../../../environments/environment.stage';
import { MessageService } from 'primeng/api';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AuthDependency } from '../../interface/types';
import { LoaderService } from '../loader/loader.service';
import { CommonServiceService } from '../common-service.service';
import { BehaviorSubject } from 'rxjs';
import { MsgMedium, SourceValue } from '../../constants/enums';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  studioSchoolList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private db: AngularFireDatabase,
    private apiHelperService: ApiHelperService,
    private userService: UserService,
    private messageService: MessageService,
    private storage: AngularFireStorage,
    private loaderService: LoaderService,
    private commonFun: CommonServiceService
  ) {}

  // --- create organizations
  createOrg(id, orgData, leagueData?) {
    return new Promise((resolve, reject) => {
      let subscription = this.db
        .object('/settings/defaults/')
        .valueChanges()
        .subscribe(async (defaults: any) => {
          subscription.unsubscribe();

          // prepare settings
          const orgSettings: any = lodash.clone(defaults);
          orgSettings.cards = defaults.cards[orgData.type.toLowerCase()];
          if (orgSettings.cards == undefined) {
            delete orgSettings.cards;
            delete orgSettings.studentReasons;
            delete orgSettings.visitTypes;
          }
          orgSettings.visuals = { orgDisplayName: orgData.name };
          let orgSmsShortCode = await this.create4DigitUniqueNo();
          let orgObj = {
            orgName: orgData.name,
            studioID: orgData.studioID ? orgData.studioID : null, // (leagueData && leagueData.conferenceId) ? leagueData.conferenceId :
            districtID: orgData.districtID ? orgData.districtID : null, // (leagueData && leagueData.conferenceId) ? leagueData.conferenceId :
            settings: orgSettings,
            type: orgData.type,
            syncSource: lodash.hasIn(orgData, 'syncSource') ? orgData.syncSource : null,
            billableIndCount: orgData.billableIndCount ? orgData.billableIndCount : null,
            administratorName: orgData.administratorName ? orgData.administratorName : null,
            paymentMethod: orgData.paymentMethod ? orgData.paymentMethod : null,
            zohoCustomerID: orgData.zohoCustomerID ? orgData.zohoCustomerID : null,
            createdAt: moment().format('x'),
            teammateClientID: orgData.teammateClientID ? orgData.teammateClientID : null,
            shippingAddress: orgData.shippingAddress || null,
            shippingCity: orgData.shippingCity || null,
            shippingState: orgData.shippingState || null,
            shippingCountry: orgData.shippingCountry || null,
            shippingPostCode: orgData.shippingPostCode || null,
            billingAddress: orgData.billingAddress || null,
            billingCity: orgData.billingCity || null,
            billingState: orgData.billingState || null,
            billingCountry: orgData.billingCountry || null,
            billingPostCode: orgData.shippingPostCode || null,
            timezones: orgData.timezones ? orgData.timezones : null,
            parentList: orgData.parentList ? orgData.parentList : null,
            studioRepresentatives: orgData.studioRepresentatives ? orgData.studioRepresentatives : null,
            Phone_Main: orgData.Phone_Main ? orgData.Phone_Main : null,
            orgSmsShortCode: orgSmsShortCode,
            regionID: leagueData && leagueData.regionId ? leagueData.regionId : null,
            conferenceID: leagueData && leagueData.conferenceId? leagueData.conferenceId : null,
            leagueID: leagueData && leagueData.leagueId ? leagueData.leagueId : null,
            defaultRYSD: orgData.defaultRYSD || null,
            certifiedOfficerName: orgData.certifiedOfficerName || null,
            certificationTimestamp: orgData.certificationTimestamp || null,
            sourceId: orgData.sourceId ? orgData.sourceId : null,
            certificate: orgData.certificate ? orgData.certificate : null,
            syncBaseUrl: orgData.syncBaseUrl ? orgData.syncBaseUrl : null,
            syncTokenUrl: orgData.syncTokenUrl ? orgData.syncTokenUrl : null,
            clientID: orgData.clientID ? orgData.clientID : null,
            clientSecret: orgData.clientSecret ? orgData.clientSecret : null,
            apiPermission: !!lodash.get(orgData, "apiList", null) ? orgData.apiList : null,
            taxExempt: orgData.taxExempt || null,
            feesControl: orgData.feesControl || false,
            locationPlusCode: orgData.locationPlusCode ? orgData.locationPlusCode : null,
            orgLat: orgData.orgLat ? orgData.orgLat : null,
            orgLong: orgData.orgLong ? orgData.orgLong : null,
            consumablePaidBy: orgData.consumablePaidBy || null,
            isAeriesSyncEnable: lodash.get(orgData, "isAeriesSyncEnable", true),
            isPortalOrg: orgData?.isPortalOrg || null,
            oneRosterKeyMapping: orgData?.oneRosterKeyMapping ? orgData.oneRosterKeyMapping : null,
            isBlackbaudSyncEnable: lodash.get(orgData, "isBlackbaudSyncEnable", true),
            isOneRosterSyncEnable: lodash.get(orgData, "isOneRosterSyncEnable", true),
            isOneRosterAutoSync: lodash.get(orgData, "isOneRosterAutoSync", false)
          };
          if (!!(orgData?.settings?.visuals?.logo)) {
            orgObj.settings.visuals.logo = orgData.settings.visuals.logo
          }
          let orgSecureInfo = {
            email: orgData.email,
          };

          try {
            await this.db.object('/organizations/' + id).set(orgObj); // must execute one by one, because db rules depends on entries created by first request to allow second request
            await this.updateOrgSecureData(id, orgSecureInfo);
            resolve(id);
          } catch (e) {
            reject(e);
          }
        });
    });
  }

  // --- get org type list
  getOrgTypeList() {
    return this.db.list(`/org-types`);
  }

  async getOrg(orgID: string, callback?: any) {
    let snapshot = await this.db
      .object(`organizations/${orgID}`)
      .query.once("value");
    let orgData = snapshot.val();
    orgData["_key"] = orgID;
    orgData["key"] = orgID;

    if (lodash.isFunction(callback)) callback(orgData);
    return orgData;
  }


  async getAllOrgs(requiredOrgPropsParam = []) {

    let requiredOrgProps;
    if (requiredOrgPropsParam.length > 0) {
      requiredOrgProps = requiredOrgPropsParam;
    } else {
      requiredOrgProps = [
        'key',
        'orgName',
        'orgLogo',
        'createdAt',
        'studioID',
        'type',
        'administratorName',
        'shippingCity',
        'shippingState',
        'shippingAddress',
        'shippingPostCode',
        'parentList.all',
        'syncSource',
        'lastAeriesImport',
        'lastBlackbaudImport',
        'lastOneRosterImport',
        'clientID',
        'clientSecret',
        'aeriesUrl',
        'schoolCode',
        'certificate',
        'districtID',
      ];
    }
    let payload = {
      by: 'parentID',
      value: this.userService.studioID,
      authDependencies: [
        {
          forRule: "static",
          requires: "userAccountInfo"
        }
      ],
      propsToReturn: requiredOrgProps
    };

    let orgDataRes = await this.apiHelperService.postToCloudFn(CloudFnNames.getOrgs, payload);
    let orgsList: any[] = lodash.get(orgDataRes, "result.data");
    if (Array.isArray(orgsList) && orgsList.length) {
      orgsList.sort((a, b) => {
        if (b.createdAt < a.createdAt) {
          return -1;
        }
        if (b.createdAt > a.createdAt) {
          return 1;
        }
        return 0;
      });
    }
    return orgsList
  }

  getOrgFromDbRealtime(studioId: string) {
    try {
      return this.db
        .list<any>('organizations', (ref) =>
          ref.orderByChild('studioID').equalTo(studioId)
        )
        .snapshotChanges()
        .subscribe({
          next: (res) => {
            if (!!res) {
              let orgList = res.map((snapshot) => ({
                key: snapshot.key,
                ...snapshot.payload.val()
              })) || [];

              orgList = orgList
                .filter((org: any) => org.isPortalOrg)
                .sort((a: any, b: any) =>
                  b.createdAt - a.createdAt
                );

              this.studioSchoolList.next(orgList);
            }
          },
          error: (error) => {
            console.error('Error getting organizations:', error);
            throw error;
          }
        });
    } catch (error) {
      throw error;
    }
  }

  async getOrgFromDbOnce(studioId: string) {
    try {
      let schoolRes = await this.db.database
        .ref('organizations')
        .orderByChild('studioID')
        .equalTo(studioId)
        .once('value');

      const schools: any[] =
        this.commonFun.convertObjToArr(schoolRes.val()) || [];

      return schools
        .filter((org: any) => org.isPortalOrg)
        .sort((a: any, b: any) =>
          b.createdAt < a.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
        ) || [];
    } catch (error) {
      console.error('Error getting organizations:', error);
      throw error;
    }
  }

  // --- delete org
  deleteOrg(id: string) {
    let deletePromises = [];
    deletePromises.push(this.db.object(`/organizations-sec/${id}`).remove());
    deletePromises.push(this.db.object(`/organizations/${id}`).remove());
    deletePromises.push(this.db.object(`/userManagementRoles/${id}`).remove());
    this.deleteOrgLogo(id);
    return Promise.all(deletePromises);
  }

  deleteOrgLogo(orgId: string) {
    if (!!(orgId)) {
      this.loaderService.show('Deleting image')
      const filePath = `/photos/organisations/${orgId}/orgLogo`;
      const storageRef = this.storage.ref(filePath);

      storageRef.getDownloadURL().subscribe({
        next: ref => {
          console.log('ref: ', ref);
          try {
            storageRef.delete();
            this.loaderService.hide()
          } catch (error) {
            this.loaderService.hide();
          }
        },
        error: err => {
          console.log('err: ', err);
          this.loaderService.hide();
        }
      })
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while deleting the organization logo. Please try again later.',
      });
      return;
    }
  }

  removeOrganizationLogo(orgId: string, callback: () => void) {
    const filePath = `/photos/organisations/${orgId}/orgLogo`;
    const storageRef = this.storage.ref(filePath);
    this.loaderService.show('Deleting image')
    storageRef.delete().subscribe({
      next: () => {
        this.loaderService.hide()
        console.log('Image deleted successfully!');
        callback();
      },
      error: (err) => {
        this.loaderService.hide()
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong. Please try again later.',
        });
        throw err;
      },
    });
  }

  create4DigitUniqueNo() {
    return new Promise((resolve, reject) => {
      try {
        let val = Math.floor(10000 + Math.random() * 90000);
        let orgSub = this.db
          .list(`/organizations/`, (ref) =>
            ref.orderByChild('orgSmsShortCode').equalTo(val)
          )
          .valueChanges()
          .subscribe((list) => {
            orgSub.unsubscribe();
            if (list.length > 0) {
              this.create4DigitUniqueNo();
            } else {
              resolve(val);
            }
          });
      } catch (e) {
        resolve('');
      }
    });
  }

  async updateOrgSecureData(orgID, updateObj) {
    if (!orgID || !updateObj) return;

    return await this.db
      .object(`organizations-sec/${orgID}/admin`)
      .update(updateObj);
  }
  tagPrefixStr = '%recipient.';
  // send welcome email to org
  sendWelcomeEmail(mailContent: any, credentialObj: any, password: string) {
    return new Promise((resolve, reject) => {
      let actualLink = environment.redirectToHigh5;
  //     let emailMessage = `Hello and welcome to High5.ID!

  //     Your login link is here:

  //     ${this.tagPrefixStr}actual_link%

  //     For the record, your user ID is:

  //     ${this.tagPrefixStr}user_email%

  //     Your password is:

  //     ${this.tagPrefixStr}password%


  //     ${mailContent ? mailContent : ''}

  //     Enjoy!

  // --The High5.ID Team--`;
  let isHidden = credentialObj.source != SourceValue.OneRoster;
  let tblContent = `
  <h3>oneRoster notes:</h3>
  <table style="border-collapse: collapse; margin: auto;">
    <tr style="background-color: #bebebd">
      <th style="border: 1px solid black; padding: 8px">Field</th>
      <th style="border: 1px solid black; padding: 8px">Sample Value</th>
    </tr>
    <tr>
      <td style="border: 1px solid black; padding: 8px">OneRoster URL</td>
      <td style="border: 1px solid black; padding: 8px">
        <a href="${this.tagPrefixStr}syncBase_url%">${this.tagPrefixStr}syncBase_url%</a>
      </td>
    </tr>
    <tr>
      <td style="border: 1px solid black; padding: 8px">Token Endpoint</td>
      <td style="border: 1px solid black; padding: 8px">
        <a href="${this.tagPrefixStr}syncToken_url%">${this.tagPrefixStr}syncToken_url%</a>
      </td>
    </tr>
    <tr>
      <td style="border: 1px solid black; padding: 8px">Consumer/client ID</td>
      <td style="border: 1px solid black; padding: 8px">${this.tagPrefixStr}client_id%</td>
    </tr>
    <tr>
      <td style="border: 1px solid black; padding: 8px">Consumer/client Secret</td>
      <td style="border: 1px solid black; padding: 8px">****${this.tagPrefixStr}clientSecret_key%</td>
    </tr>
  </table>`;

  let csvFieldListContent = `
  <p style="margin-top: 15px">
    Whether provided in ${credentialObj.source}, we’d like read access to the following
    fields:
  </p>
  <h4>Who</h4>
  <ul>
    <li>Students</li>
    <li>Staff</li>
    <li>Guardians</li>
  </ul>
  <h4>Required Fields</h4>
  <ul>
    <li>First Name</li>
    <li>Last Name</li>
    <li>Student # / Staff #</li>
    <li>Grade</li>
    <li>Role [staff/student]</li>
  </ul>
  <h4>Optional, useful for communications</h4>
  <ul>
    <li>Student email</li>
    <li>Student phone</li>
  </ul>
  <h4>Optional, useful for security challenges / parent communications</h4>
  <ul>
    <li>Date of birth</li>
    <li>Guardian email</li>
    <li>Guardian phone</li>
    <li>Guardian name</li>
  </ul>`;
  // 
  // ${!isHidden ? tblContent : ''}
  // ${credentialObj.source == 'csv' ? csvFieldListContent : ''}

  let emailMessage = `<div style="width: 100%; font-family: 'Times New Roman', Times, serif;">
  <h2>Hello and Welcome to High5.ID!</h2>

  <h4>Please use the following credentials to log into the High5 Admin panel:</h4>
  <ul>
    <li>
      Credential
      <ul>
        <li>Login link - ${this.tagPrefixStr}actual_link%</li>
        <li>User ID - ${this.tagPrefixStr}user_email%</li>
        <li>Password - ${this.tagPrefixStr}password%</li>
      </ul>
    </li>
  </ul>
  <ul>
    <li>
      Please forward the following information to your IT department to ensure smooth operations:
      <ul>
        <li style="list-style-type: circle">
          Please enable/whitelist access to the domains listed on this page:
        </li>
        <ul>
          <li>
            <a href="https://high5.id/wp/knowledge-base/which-domains-should-my-it-group-whitelist/" style="list-style-type: square"
              >https://high5.id/wp/knowledge-base/which-domains-should-my-it-group-whitelist/</a
            >
          </li>
          <li>
            (this will allow the High5 Admin panel to work correctly)
          </li>
        </ul>
      </ul>
      <ul>
        <li style="list-style-type: circle">
          Here is a diagnostics page to test that all domains are accessible:
        </li>
        <ul>
          <li style="list-style-type: square">
            <a href="https://it-support.high5.id/diagnostics">High5 IT Support</a>
          </li>
        </ul>
      </ul>
      <ul>
        <li style="list-style-type: circle">
          Please whitelist the following source for emails to your users:
        </li>
        <ul>
          <li style="list-style-type: square">
            <a href="https://high5.id">@high5.id</a>
          </li>
          <li style="list-style-type: square">
            (this will help with “Forgot my password” notifications)
          </li>
        </ul>
      </ul>
    </li>
  </ul>
  <p>Enjoy!</p>
  <p style="font-size: 16px;">--The High5.ID! Team--</p>
</div>
`
      // --- mailgun post data
      const mailgunObj = {
        from: environment.mailFromAddress,
        to: [credentialObj.email],
        text: emailMessage,
        subject: `Welcome to High5.ID!`,
        recipient_variables: {
          [credentialObj.email]: {
            actual_link: actualLink,
            user_email: credentialObj.email,
            password: password,
            syncBase_url: credentialObj.syncBaseUrl,
            syncToken_url: credentialObj.syncTokenUrl,
            client_id: credentialObj.clientId,
            clientSecret_key: credentialObj.clientSecret.substr((credentialObj.clientSecret).length-4),
            source: credentialObj.source,
          },
        },
      };

      this.apiHelperService
        .post(environment.mailgunObj.endPoint, {
          data: [mailgunObj],
          type: 'single',
          emailType: MsgMedium.EMAIL_HTML,
        })
        .subscribe({
            next: (res: any) => {
              if (res.status == 'ok') {
                resolve(true);
              }
            },
            error: (err) => {
              console.log('err: ', err);
              resolve(false);
            },
          }
        );
    });
  }

  // --- send email on admin@high5.id when create new new org by teammate or high5
  sendEmailToHigh5(orgObj: any, credentialObj: any) {
    return new Promise((resolve, reject) => {
      let studioSub = this.db
        .object(`/studios/${this.userService.studioID}`)
        .valueChanges()
        .subscribe((studio: any) => {
          studioSub.unsubscribe();
          // --- mailgun post data
          const mailgunObj = {
            from: environment.mailFromAddress,
            to: [environment.superAdminEmail],
            text: `The teammate\n${
              studio.studioName
            }\njust created the org\n${
              orgObj.name
            }\n\nContact details:\nadmin email ID: ${credentialObj.email}\nadmin Name: ${ orgObj.administratorName ? orgObj.administratorName : '-'}\nadmin Phone: ${orgObj.Phone_Main ? orgObj.Phone_Main : '-'}`,
            subject: `org ${orgObj.name} created by ${studio.studioName}`,
            recipient_variables: {},
          };
          this.apiHelperService
            .post(environment.mailgunObj.endPoint, {
              data: [mailgunObj],
              type: 'single',
              emailType: 'email',
            })
            .subscribe(
              (res: any) => {
                console.log('res: ', res);
                resolve(true);
              },
              (err) => {
                console.log('err: ', err);
                resolve(false);
              }
            );
        });
    });
  }

    // --- remove organization individuals images (from firebase (legacy) & s3)
    async removeOrgIndImages(
      orgID: string,
      authDependencies?: AuthDependency[]
    ) {
      if (!orgID) throw new Error("Params missing!");

      let reqBody = { orgID, authDependencies };
      let response: any = await this.apiHelperService.postToCloudFn(
        CloudFnNames.removeOrgIndImages,
        reqBody
      );
      response = lodash.get(response, "result");
      if (!response || response.success != 1)
        throw new Error(lodash.get(response, "message"));

      return true;
    }
      // get organization "Photo Used in ID" preference value
  /**
   * @deprecated use @method getPreferenceByInheritance instead (from preferences provider)
   */
  getSpecificOrgPrefValue(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let response = this.getSpecificOrgPrefValueUsingId(
        id,
        this.userService.orgID,
        this.userService.studioID
      );
      resolve(response);
    });
  }

    // get organization "Photo Used in ID" preference value
  /**
   * @deprecated use @method getPreferenceByInheritance instead (from preferences provider)
   */
  getSpecificOrgPrefValueUsingId(
    prefId: string,
    orgID: string,
    studioId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!orgID) return resolve(null);

      // --- get dependent value
      let orgSub = this.db
        .list(`/organizations/${orgID}/settings/org-preference/`, (ref) =>
          ref.orderByChild("id").equalTo(prefId)
        )
        .valueChanges()
        .subscribe((res: any) => {
          orgSub.unsubscribe();
          if (res.length > 0) {
            resolve(res[0].value);
          } else {
            let studioSub = this.db
              .list(`/studios/${studioId}/settings/org-preference`, (ref) =>
                ref.orderByChild("id").equalTo(prefId)
              )
              .valueChanges()
              .subscribe((studioPref: any) => {
                studioSub.unsubscribe();
                if (studioPref.length > 0) {
                  resolve(studioPref[0].value);
                } else {
                  let saSub = this.db
                    .list(`/settings/org-preference`, (ref) =>
                      ref.orderByChild("id").equalTo(prefId)
                    )
                    .valueChanges()
                    .subscribe((saPref: any) => {
                      saSub.unsubscribe();
                      resolve(saPref[0].value);
                    });
                }
              });
          }
        });
    });
  }

  // --- update org
  updateOrg(data: any, id: string, path: string = null) {
    return this.db.object(path ? path : '/organizations/' + id).update(data);
  }

  removeContactFromOrg(orgId: string, contactId: string) {
    return this.db.object(`organizations/${orgId}/portalContact/${contactId}`).set(null);
  }

  getContactFromOrg(orgId: string) {
    return this.db.object(`/organizations/${orgId}/portalContact`).query.once('value');
  }

  getFirebaseIdOfOrg(id: string) {
    return this.db.object(`/organizations/${id}/firebaseId`).query.once('value');
  }

  async getOrgNameByOrgID(orgId: string): Promise<string> {
    let orgData: any = (await this.db.object(`/organizations/${orgId}`).query.once('value')).val();
    return orgData.orgName;
  }

  async getIndCount(
    sourceId: string,
    certificate: string,
    syncBaseUrl: string,
    syncSource: string,
    oneRosterAccessToken: string
  ) {
    if (
      !(certificate || oneRosterAccessToken) ||
      !sourceId ||
      !syncBaseUrl ||
      !syncSource
    ) {
      throw new Error('Params missing!');
    }
    try {
    let [res, err] = await this.commonFun.executePromise(
        this.apiHelperService.postToCloudFn(
          CloudFnNames.testSync,
          {
            sourceId: sourceId,
            certificate,
            syncBaseUrl,
            syncSource,
            oneRosterAccessToken,
          }
        )
      );
      if (err) {
        throw err
      }
      return res
    } catch (error) {
      throw error;
    }
  }

  async updateSecData(
    orgId: string,
    authId: string,
    obj: any,
    isMainAdmin?: boolean
  ) {
    if (!orgId || !authId) throw new Error('params missing!');

    let secData: any = {};
    if (obj.hasOwnProperty('email') && obj.email) {
      lodash.set(secData, 'email', obj.email);
    }

    let updatePromises = [];

    if (isMainAdmin && !lodash.isEmpty(secData)) {
      updatePromises.push(this.updateOrgSecureData(orgId, secData));
    }

    if (!lodash.isEmpty(secData))
      updatePromises.push(
        this.addUserSecDataInOrgSecNode(orgId, authId, secData)
      );
    await Promise.all(updatePromises);
  }

  // add org secure data in database
  addUserSecDataInOrgSecNode(orgId: string, authId: string, data: any) {
    return this.db
      .object(`/organizations-sec/${orgId}/users/${authId}`)
      .update(data);
  }
}
